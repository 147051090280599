<template>
  <div class="ads-item">
    <div v-show="isAdFilled" class="ads-borded" :class="classs">
      <div class="ads-borded-title">
        Advertisement
      </div>
      <div ref="adsense" class="ad-sync" />
    </div>
    <div v-show="showDebug" class="ad-msg">
      {{ ads }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    ads: {
      type: [String, Object],
      required: true
    },
    showDebug: {
      type: Boolean,
      required: true
    },
    classs: {
      type: String,
      default: null,
      required: false
    },
  },
  data () {
    return {
      isAdFilled: true, // 默认显示
    }
  },
  mounted () {
    if (this.ads && typeof this.ads === 'object') {
      this.renderSyncAd();
    }
  },
  methods: {
    renderSyncAd () {
      // 清空容器，以防止重复插入造成干扰
      this.$refs.adsense.innerHTML = '';

      // 注入广告参数的 script
      const scriptVars = document.createElement('script');
      scriptVars.type = 'text/javascript';
      scriptVars.innerHTML = `
        google_ad_client = "${this.ads.google_ad_client}";
        google_ad_slot = "${this.ads.google_ad_slot}";
        google_ad_width = ${this.ads.google_ad_width};
        google_ad_height = ${this.ads.google_ad_height};
      `;
      this.$refs.adsense.appendChild(scriptVars);

      // 插入 show_ads.js 脚本触发广告渲染
      const scriptShowAd = document.createElement('script');
      scriptShowAd.type = 'text/javascript';
      scriptShowAd.src = "https://pagead2.googlesyndication.com/pagead/show_ads.js";
      this.$refs.adsense.appendChild(scriptShowAd);
    },
  },
}
</script>